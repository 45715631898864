@use "../config/" as *;
.waf-scorecard {
    // TODO add stadium icon
    // TODO add clock icon
    .matchinfo-tab-container {
        margin: 0;
        @extend %p-4;
        @extend %my-4;
        .matchinfo-item {
            @extend %py-3;
            @extend %flex-sb-c;
            &:not(:last-child) {
                border-bottom: .1rem solid clr(secondary-800, 2);
            }
        }
        .info {
            width: 45%;
            @extend %text-right;
            @extend %font-12-pb;
            @include mq(col-md) {
                font-size: 1.4rem;
            }
            &-label {
                @extend %font-12-pr;
                @include mq(col-md) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-scorecard {
        .matchinfo-tab-container {
            margin-top: 0;
            padding-top: var(--space-6);
            .info {
                font-size: 1.4rem;
            }
            .info-label {
                width: 45%;
                flex-shrink: 0;
                position: relative;
                margin-right: 1rem;
                font-size: 1.4rem;
            }
            .info {
                flex: 1;
                text-align: left;
            }
            .matchinfo-item {
                &:nth-child(3) {
                    .info {
                        display: flex;
                        &::before {
                            width: 3rem;
                            font-weight: 400;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include icon(timer, 20);
                        }
                    }
                }
                &:nth-child(2) {
                    .info {
                        display: flex;
                        &::before {
                            width: 3rem;
                            font-weight: 400;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include icon(stadium);
                        }
                    }
                }
            }
        }
    }
}