@use "./config" as *;
.waf-cricketscorecard {
    .team-info-sub-wrap {
        @extend %w-100;
        @extend %neutral-50-bg;
        @extend %half-radius;
        @extend %hidden;
        @include mq(col-md) {
            max-width: 45rem;
        }
        .team-info {
            flex: 1;
            cursor: pointer;
            @extend %flex-c-c;
            @extend %p-2;
            @extend %uppercase;
            .team-name {
                flex-shrink: 0;
                font-size: 1rem;
            }
            &.tab-active {
                font-weight: 700;
                @extend %primary-300-bg;
            }
        }
        .inning-score {
            @extend %d-none;
        }
    }
    .spider-section,
    .wagonwheel-section {
        @extend %relative;
        @extend %w-100;
        .svg-section-container {
            margin: var(--space-8) auto 0;
            @include square(30rem);
            @extend %relative;
            @extend %flex-c-n;
        }
        .svg-graph {
            @include background(null, "matchcenter/spider-graph.svg", center / cover no-repeat);
            @include square(25rem);
            @extend %relative;
            @extend %mx-auto;
        }
        .svg-section {
            @include square(100%);
            @extend %flex-c-n;
        }
        .zone-listing {
            @include square(100%);
            @extend %absolute;
            .zone-item {
                @extend %absolute;
            }
            .item-one {
                top: -1.3rem;
                left: 7.5rem;
            }
            .item-two {
                top: -1.3rem;
                left: 21.5rem;
            }
            .item-three {
                top: 6rem;
                left: 28rem;
            }
            .item-four {
                top: 16rem;
                left: 28rem;
            }
            .item-five {
                top: 25rem;
                left: 20rem;
            }
            .item-six {
                top: 25rem;
                left: 9rem;
            }
            .item-seven {
                top: 17rem;
                left: 1rem;
            }
            .item-eight {
                top: 6rem;
                left: 0.5rem;
            }
        }
        .svg-div {
            top: 57%;
            @include position-combo(center);
            width: 19.4rem;
            height: 20rem;
            @extend %hidden;
            @extend %circle-radius;
        }
        .controls-list {
            flex-wrap: wrap;
            @extend %flex-c-c;
            @extend %m-auto;
            @extend %py-4;
        }
        .controls-item {
            width: 5.4rem;
            height: 4.8rem;
            flex-shrink: 0;
            @extend %flex-column-c-c;
            @extend %gap-2;
            @extend %neutral-50;
            .number,
            .runs {
                line-height: 1;
                @extend %font-12-pr;
                @extend %flex-c-c;
            }
            &.item1 {
                border-radius: var(--half-radius) 0 0 var(--half-radius);
                @extend %spider-1s-bg;
            }
            &.item2 {
                @extend %spider-2s-bg;
            }
            &.item3 {
                @extend %spider-3s-bg;
            }
            &.item4 {
                @extend %spider-4s-bg;
            }
            &.item5 {
                border-radius: 0 var(--half-radius) var(--half-radius) 0;
                @extend %spider-5s-bg;
                @extend %neutral-900;
            }
        }
    }
    .wagonwheel-section {
        .svg-graph {
            @include background(null, "matchcenter/wagon-wheel-graph.svg", center / cover no-repeat);
        }
        .zone-item {
            &::before {
                content: "";
                position: absolute;
                width: 10rem;
                height: 10rem;
                rotate: 225deg;
                opacity: 0
            }
            &.active::before {
                opacity: 1;
            }
        }
        .item {
            &-one {
                &::before {
                    top: 1.7rem;
                    left: 2.5rem;
                    @include background(null, "matchcenter/wagon-wheel-shape-1.png", top/contain no-repeat);
                }
            }
            &-two {
                &::before {
                    top: 2.7rem;
                    left: -10.4rem;
                    @include background(null, "matchcenter/wagon-wheel-shape-2.png", top/contain no-repeat);
                }
            }
            &-three {
                &::before {
                    top: 0.6rem;
                    left: -12rem;
                    @include background(null, "matchcenter/wagon-wheel-shape-3.png", top/contain no-repeat);
                }
            }
            &-four {
                &::before {
                    top: -8.3rem;
                    left: -11rem;
                    @include background(null, "matchcenter/wagon-wheel-shape-4.png", top/contain no-repeat);
                }
            }
            &-five {
                &::before {
                    top: -12.3rem;
                    left: -8rem;
                    @include background(null, "matchcenter/wagon-wheel-shape-5.png", top/contain no-repeat);
                }
            }
            &-six {
                &::before {
                    top: -11.3rem;
                    left: 0rem;
                    @include background(null, "matchcenter/wagon-wheel-shape-6.png", top/contain no-repeat);
                }
            }
            &-seven {
                &::before {
                    top: -8.3rem;
                    left: 3rem;
                    @include background(null, "matchcenter/wagon-wheel-shape-7.png", top/contain no-repeat);
                }
            }
            &-eight {
                &::before {
                    top: -0.5rem;
                    left: 4.8rem;
                    @include background(null, "matchcenter/wagon-wheel-shape-8.png", top/contain no-repeat);
                }
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-cricketscorecard {
        .spider-section {
            position: relative;
            .inner-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: var(--space-4);
            }
            .svg-section-container {
                margin: 0;
            }
        }
        .team-info-sub-wrap {
            .team-info { 
                .team-name {
                    font-size: 1.4rem;
                    flex-shrink: 1;
                }
            }
        }
    }
}