@use "../config/" as *;
.commentary-tab-container {
    @extend %pb-5;
    @extend %px-5;
    p,
    .commentary-text {
        @extend %font-14-pr;
        @include mq(col-md) {
            font-size: 1.6rem
        }
    }
    .text-section,
    .content-section,
    .highlight-section {
        @extend %py-4;
    }
    .sub-title,
    .title,
    .highlight-title,
    .commentary-title,
    .over-count {
        @extend %font-14-pb;
        @include mq(col-md) {
            font-size: 1.6rem
        }
    }
    .commentary-title {
        @extend %mt-0;
        @extend %mb-2;
        &::before {
            width: 4rem;
            content: "";
            display: inline-block;
        }
    }
    .over-extra-score {
        @extend %neutral-100-bg;
        @extend %w-100;
        @extend %text-center;
    }
    .over {
        &-item {
            &.six .over-details {
                @extend %primary-500-bg;
            }
            &.four .over-details {
                @extend %primary-400-bg;
            }
            &.wicket .over-details {
                @extend %error-900-bg;
                .over-score {
                    @extend %neutral-50;
                }
            }
        }
        &-score {
            flex-direction: column-reverse;
            @extend %uppercase;
            @extend %flex-c-c;
            @extend %w-100;
            &:has(span) {
                @extend %neutral-50-bg;
            }
        }
        &-details {
            width: 3rem;
            height: 3rem;
            flex-shrink: 0;
            line-height: 1;
            @extend %half-radius;
            @extend %relative;
            @extend %flex-column-c-c;
            @extend %primary-100-bg;
        }
        &-wrap {
            @extend %flex-n-fs;
            @extend %gap-4;
        }
        &-info {
            .btn-site {
                margin: var(--space-4) auto;
                display: inherit;
                @extend %font-14-pb;
                .btn-text {
                    @extend %font-14-pb;
                }
            }
            .head {
                @extend %mt-4;
                @extend %p-2-4;
                @extend %full-radius;
                @extend %primary-100-bg;
                &-wrap {
                    @extend %flex-sb-c;
                    .over-info {
                        @extend %gap-3;
                        @extend %flex;
                        .over-text {
                            &.runs {
                                @extend %relative;
                                &:after {
                                    content: '';
                                    width: .6rem;
                                    height: .2rem;
                                    left: var(--space-2-neg);
                                    @extend %pos-y-center;
                                    @extend %neutral-800-bg;
                                }
                            }
                        }
                    }
                }
            }
            .over-text {
                @extend %font-15-pb;
            }
        }
        &-count {
            position: absolute;
            right: -5.3rem;
            top: 0rem;
            width: 4rem;
            @extend %flex-c-c;
            &::after {
                content: "-"
            }
        }
        &-item {
            padding: var(--space-4) 0;
            &.over-removed {
                .commentary-title {
                    &::before {
                        width: 0;
                    }
                }
            }
            .rpm-over {
                @extend %d-none;
            }
        }
    }
    .btn-action {
        width: max-content;
        padding: var(--space-2) var(--space-8);
        margin: var(--space-3) auto;
        cursor: pointer;
        border: .1rem solid var(--secondary-800);
        @extend %uppercase;
        @extend %font-12-pb;
        @extend %half-radius;
        @extend %secondary-800;
        @extend %transparent-bg;
        &::after {
            @include icon(drop-arrow);
            @extend %ml-2;
        }
    }
}