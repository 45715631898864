@use "../config/" as *;
.waf-cricketscorecard {
    @extend %p-4;
    .waf-head {
        .title {
            @extend %font-16-pb;
            @extend %uppercase;
            @extend %secondary-800;
            @extend %mb-8;
            @include mq(col-md) {
                font-size: 2rem
            }
        }
    }
    .tabs {
        gap: 0;
    }
    .tab-name {
        flex-shrink: 0;
        min-width: max-content;
        @extend %px-4;
        @extend %uppercase;
        @extend %flex-c-c;
    }
    .scorecard-tab {
        .tab-name {
            border: .1rem solid clr(neutral-500, 3);
            &.active {
                font-weight: 700;
            }
        }
    }
    .scorecard-container {
        @extend %mt-4;
        @extend %pure-white-900-bg;
    }
    .tabs {
        overflow: auto;
    }
    .tab-container {
        .tab-name {
            background-color: transparent;
            border-bottom: .1rem solid clr(neutral-500, 3);
            text-transform: unset;
            &.active,
            &.tab-active {
                border-bottom: .3rem solid clr(secondary-800);
                font-weight: 700;
                @extend %secondary-800;
            }
        }
        .team-info-wrap {
            @extend %flex-sb-c;
            @extend %gap-4;
            @extend %my-4;
            @include mq(col-md) {
                justify-content: flex-end;
            }
            .color-fill {
                width: 1.4rem;
                height: 1.4rem;
                display: inline-block;
                @extend %mr-1;
                @extend %circle-radius;
                @extend %secondary-800-bg;
            }
            .team-b .color-fill {
                @extend %primary-500-bg;
            }
        }
        .waf-select-box {
            max-width: 30rem;
            width: calc(100% - 7rem);
            position: relative;
            padding: var(--space-2);
            @extend %full-radius;
            @extend %neutral-50-bg;
            @extend %ml-auto;
            @extend %my-4;
            @extend %mr-2;
            &::before {
                content: "Innings";
                position: absolute;
                left: -1rem;
                top: 50%;
                height: 1rem;
                transform: translateX(-100%) translateY(-50%);
                width: max-content;
                background: transparent;
                @extend %font-12-pb;
            }
        }
    }
    .no-data {
        width: max-content;
        @extend %mx-auto;
        @extend %p-3;
        @extend %flex-c-c;
        @extend %primary-300-bg;
        @extend %secondary-800;
        @extend %half-radius;
    }
}
@include mq(col-md) {
    .waf-cricketscorecard {
        padding-inline: 0;
        .layout-wrapper {
            max-width: 100%;
        }
    }
}
@include mq(col-lg) {
    .waf-cricketscorecard {
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
    }
}