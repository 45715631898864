@use "../config/" as *;
.scorecard-tab-container {
    @extend %px-3;
    .impact-player-legends {
        @extend %flex-sb-c;
        @extend %p-2;
        @extend %mx-2;
        @extend %gap-2;
        @extend %half-radius;
        @extend %primary-100-bg;
        .label,
        .label-txt {
            @extend %font-12-pb;
            @include mq(col-md) {
                font-size: 1.4rem
            }
        }
        .label-txt {
            @extend %flex-n-c;
            &::after {
                content: "";
                width: 2rem;
                height: 2rem;
                flex-shrink: 0;
                @include icon(impact-player);
                @extend %flex-c-c;
                @extend %neutral-50;
                @extend %circle-radius;
                @extend %ml-4;
            }
            &.in::after {
                @extend %success-900-bg;
            }
            &.out::after {
                @extend %error-900-bg;
            }
        }
        .impact-player-label {
            @extend %flex-n-c;
            @extend %gap-2;
            @include mq(col-md) {
                gap: var(--space-4)
            }
        }
    }
    .impact-player-container {
        @extend %mb-4;
        .impact-player-name span,
        .title {
            @extend %font-12-pb;
            @include mq(col-md) {
                font-size: 1.6rem
            }
        }
        span {
            @extend %font-12-pb;
        }
    }
    .team-impact-player-body {
        @extend %mt-4;
        @extend %p-2;
        @extend %primary-100-bg;
        @extend %half-radius;
        .team-impact-player {
            &-list {
                padding: 0;
                list-style: none;
            }
            &-item {
                @extend %flex-column;
                @extend %gap-2;
                @extend %relative;
                @extend %pr-14;
                @extend %pl-2;
                @extend %py-2;
                .player-in {
                    &::before {
                        width: 2rem;
                        height: 2rem;
                        display: inline-flex;
                        @include icon(impact-player);
                        @extend %flex-c-c;
                        @extend %p-1;
                        @extend %neutral-50;
                        @extend %success-900-bg;
                        @extend %circle-radius;
                        @extend %mr-2;
                    }
                }
                .player-out {
                    &::before {
                        width: 2rem;
                        height: 2rem;
                        display: inline-flex;
                        @include icon(impact-player);
                        @extend %flex-c-c;
                        @extend %p-1;
                        @extend %neutral-50;
                        @extend %error-900-bg;
                        @extend %circle-radius;
                        @extend %mr-2;
                    }
                }
                .player-replace {
                    width: 2.5rem;
                    height: 2.5rem;
                    right: var(--space-2);
                    @extend %flex-c-c;
                    @extend %circle-radius;
                    @extend %pos-y-center;
                    @extend %neutral-50;
                    @extend %neutral-900-bg-2;
                    &::after {
                        @include icon(substitute);
                    }
                }
                .player-name {
                    @extend %font-12-pb;
                    @include mq(col-md) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
    .team {
        &-score-detail {
            @extend %flex-sb-c;
            @extend %p-3;
            @extend %neutral-50-bg;
            @extend %my-4;
            @extend %half-radius;
            @include mq(col-md) {
                margin-bottom: 0;
            }
        }
        &-flag {
            width: 3.5rem;
            flex-shrink: 0;
            @extend %mr-2;
            img {
                object-fit: contain
            }
        }
        &-name {
            @extend %font-14-pb;
            &.shortname {
                display: none;
            }
        }
    }
    .detail-part1 {
        @extend %flex-n-c;
    }
    .detail-part2 {
        @extend %text-right;
        .runs,
        .runs,
        .over-runrate,
        .overs {
            @extend %font-10-pr;
            @include mq(col-md) {
                font-size: 1.2rem;
            }
        }
    }
    // .sub-container-wrap {
    //     @extend %px-2;
    // }
    .table {
        margin-top: 0;
        @extend %w-100;
        &-row {
            @extend %flex-sb-n;
            &:not(:last-child) {
                border-bottom: .1rem solid clr(neutral-900, 5);
            }
            &.player-in {
                .name {
                    @extend %flex;
                    @extend %gap-2;
                    &::after {
                        width: 2rem;
                        height: 2rem;
                        display: inline-flex;
                        @include icon(impact-player);
                        @extend %flex-c-c;
                        @extend %p-1;
                        @extend %neutral-50;
                        @extend %success-900-bg;
                        @extend %circle-radius;
                        @extend %ml-2;
                    }
                }
            }
            &.player-out {
                .name {
                    @extend %flex;
                    @extend %gap-2;
                    &::after {
                        width: 2rem;
                        height: 2rem;
                        display: inline-flex;
                        @include icon(impact-player);
                        @extend %flex-c-c;
                        @extend %p-1;
                        @extend %neutral-50;
                        @extend %error-900-bg;
                        @extend %circle-radius;
                        @extend %ml-2;
                    }
                }
            }
        }
        &-data {
            width: calc(60% / 5);
            flex-shrink: 0;
            @extend %text-center;
            @extend %p-2;
            @include flex-config(flex, column, center, center);
            span {
                @extend %uppercase;
                @extend %font-12-pm;
            }
            &.label {
                flex-basis: 40%;
                width: 40%;
                flex-shrink: 0;
                @extend %text-left;
                @extend %flex-column-c-fs;
                span {
                    text-transform: unset;
                }
                .name {
                    text-transform: unset;
                    @extend %font-12-pb;
                    @include mq(col-md) {
                        font-size: 1.4rem
                    }
                }
                .status {
                    text-transform: unset;
                    opacity: 0.8;
                    @extend %font-12-pr;
                }
            }
            &.player-runs {
                @extend %font-14-pb;
            }
            &.player-wickets {
                @extend %primary-400-bg-4;
            }
        }
        &-head {
            @extend %primary-100-bg;
            @extend %full-radius;
            .table-data {
                span {
                    @extend %font-12-pb;
                }
                &.label {
                    flex-direction: row;
                    @extend %flex-fs-c;
                    &::before {
                        @extend %pr-2;
                    }
                }
            }
        }
        &-body {
            .table-data {
                min-height: 7rem;
            }
        }
    }
    .batting-scorecard {
        .table-head {
            .label {
                &::before {
                    @include icon(bat)
                }
            }
        }
    }
    .wicket-scorecard {
        .table-head {
            .label {
                &::before {
                    display: none;
                }
            }
        }
        .table-body {
            .table-data {
                &.label {
                    width: 50%;
                    flex-direction: row;
                    @extend %flex-fs-c;
                    .fall-wicket-run {
                        width: 6rem;
                        flex-shrink: 0;
                        @extend %text-center;
                        @extend %mr-1;
                        @extend %font-14-pb;
                    }
                    .name {
                        @extend %font-14-pr;
                    }
                }
            }
        }
        .table-data {
            &.label {
                flex: 1;
            }
            &.overs {
                width: 6rem;
                flex-shrink: 0;
            }
        }
    }
    .bowling-scorecard {
        .table-head {
            .label {
                &::before {
                    @include icon(ball)
                }
            }
        }
        .table-data {
            width: calc(60% / 7);
        }
    }
    .extras-scorecard {
        @extend %p-2;
        @extend %neutral-50-bg;
        @extend %mb-4;
        @extend %full-radius;
        .label {
            @extend %font-12-pb;
        }
        .extra-info {
            @extend %font-12-pm;
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-scorecard {
        .scorecard-tab-container {
            .team-impact-player {
                &-item {
                    flex-direction: row;
                    align-items: center;
                    gap: var(--space-5);
                    .player-replace {
                        position: unset;
                        translate: 0;
                    }
                }
            }
            .wicket-scorecard {
                .table {
                    .label {
                        flex: 1
                    }
                }
                .overs {
                    flex: unset;
                    width: 10rem;
                }
            }
            .detail-part2 {
                .runs,
                .overs,
                .over-runrate {
                    font-size: 1.4rem
                }
            }
            .team-name {
                font-size: 1.4rem;
                &.shortname {
                    display: none;
                }
            }
            .table-head {
                .table-data {
                    span {
                        font-size: 1.4rem;
                    }
                }
            }
            .table-data {
                width: calc(65% / 8);
                span {
                    font-size: 1.4rem;
                }
                &.label {
                    width: 40%;
                    flex-basis: 40%;
                    .name {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}