@use "../config/" as *;
.card {
    &-item {
        @extend %pure-white-900-bg;
        @extend %p-4;
        @extend %neutral-800;
        &.completed {
            .status {
                @extend %error-900;
            }
        }
        &.upcoming {
            .card-content .time {
                display: block;
            }
        }
        &.live {
            .status {
                @extend %flex;
                @extend %gap-1;
                &::before {
                    content: "";
                    width: 2rem;
                    height: 2rem;
                    flex-shrink: 0;
                    @extend %d-block;
                    @include background(null, "svg/live.svg", center/cover)
                }
            }
        }
    }
    &-header {
        border-bottom: .1rem solid clr(neutral-50);
        @extend %flex-n-fs;
        @extend %gap-4;
        @extend %pb-2;
        @extend %mb-2;
        span {
            @extend %font-14-pb;
        }
        .matchinfo {
            font-weight: 700;
            order: -1;
            &::after {
                content: "|";
                @extend %mx-1;
            }
        }
        .title {
            order: 2;
            &::before {
                content: "|";
                @extend %mx-1;
            }
        }
        .status {
            flex-shrink: 0;
            @extend %uppercase;
            @extend %ml-auto;
        }
        .head-wrap {
            display: inline-flex;
            flex-wrap: wrap;
        }
    }
    &-content {
        border-bottom: .1rem solid clr(neutral-50);
        @extend %relative;
        @extend %pb-2;
        @extend %mb-2;
        .team {
            @extend %flex-sb-c;
            @extend %gap-2;
            @extend %py-2;
            &-info {
                @extend %flex-n-c;
                @extend %gap-2;
                img {
                    flex-shrink: 0;
                    width: 3rem;
                    @include mq(col-md) {
                        width: 4.5rem;
                    }
                }
            }
            &-name {
                line-height: 1;
                @extend %font-14-pb;
                @extend %uppercase;
                @include mq(col-md) {
                    font-size: 1.6rem
                }
            }
            &-score {
                width: 50%;
                flex-wrap: wrap;
                flex-shrink: 0;
                gap: 0 var(--space-3);
                @extend %flex-fe-n;
                .score {
                    @extend %font-14-pb;
                    @include mq(col-md) {
                        font-size: 2rem
                    }
                }
                .si-overs {
                    @extend %font-14-pr;
                }
                .scorecard-innings-wrap {
                    @extend %flex-fs-fe;
                    @extend %gap-1;
                    @extend %my-1;
                    @extend %neutral-800-6;
                    @extend %relative;
                    &:not(:last-child) {
                        .si-overs {
                            height: 1.5rem;
                            @extend %hidden;
                            @extend %flex-column;
                        }
                        &::after {
                            content: "";
                            width: .1rem;
                            height: 70%;
                            right: var(--space-2-neg);
                            @extend %pos-y-center;
                            @extend %neutral-800-bg-6;
                        }
                    }
                }
                &.won,
                &.current,
                &.team-current {
                    .scorecard-innings-wrap:last-child {
                        @extend %secondary-800;
                    }
                }
                &:has(.scorecard-innings-wrap:nth-child(3)) {
                    .scorecard-innings-wrap:nth-child(2) {
                        @extend %d-none;
                    }
                }
            }
        }
        .time {
            right: 0;
            @extend %d-none;
            @extend %font-14-pb;
            @extend %pos-y-center;
        }
    }
    &-footer {
        @extend %my-4;
        .team-status {
            @extend %font-12-pm;
            @include mq(col-md) {
                font-size: 1.4rem
            }
        }
    }
}
.end-match-footer {
    @extend %my-4;
    .text {
        @extend %font-12-pm;
        @include mq(col-md) {
            font-size: 1.4rem
        }
    }
    .player-name {
        @extend %font-14-pb;
        @extend %primary-900;
        @include mq(col-md) {
            font-size: 1.6rem
        }
    }
}
.live-match-footer {
    .player {
        &-details {
            flex: 1;
            @extend %neutral-50-bg;
            @extend %my-4;
            border-radius: 0 0 var(--full-radius) var(--full-radius);
            .label {
                border-radius: var(--full-radius) var(--full-radius) 0 0;
                @extend %primary-100-bg;
                @extend %d-block;
                @extend %w-100;
                @extend %p-2;
                @extend %font-12-pb;
            }
        }
        &-info {
            @extend %p-2;
            @extend %flex-n-c;
            @extend %gap-2;
            &:nth-child(2) {
                &::before {
                    @include icon(play, 14);
                }
            }
        }
        &-score {
            @extend %ml-auto;
            @extend %font-12-pb;
            span {
                font: inherit;
            }
        }
        &-name {
            @extend %font-12-pm;
        }
    }
    .runs-detail {
        @extend %flex-n-c;
        .run {
            width: 3rem;
            height: 3rem;
            flex-shrink: 0;
            line-height: 1;
            @extend %uppercase;
            @extend %half-radius;
            @extend %ml-2;
            @extend %flex-column-c-c;
            @extend %primary-100-bg;
            span {
                @extend %uppercase;
                @extend %w-100;
                @extend %text-center;
            }
            .over-extra-score {
                @extend %neutral-50-bg;
            }
            &:has(>span):has(>span) {
                @extend %neutral-100-bg;
            }
            span:has(span) {
                @extend %flex-column-c-c;
            }
            &.six {
                @extend %primary-500-bg;
            }
            &.four {
                @extend %primary-400-bg;
            }
            &.wicket {
                @extend %error-900-bg;
                @extend %neutral-50;
            }
            &-info {
                @extend %flex;
            }
        }
    }
    @include mq(col-md) {
        .innings-info {
            display: flex;
            gap: var(--space-4);
        }
    }
}